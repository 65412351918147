import { useInfiniteQuery } from 'react-query'
import { TQueryParamsGetData, TResApiErr, TResDataListApi } from '@configs/interface.config'
import { checkAuth } from '@libs/localStorage'

export * from './auth'
export * from './media'
export * from './role'
export * from './taxonomy'
export * from './user'
export * from './post'
export * from './advertisement'
export * from './option'
export * from './company'

/**
 *
 * @param request
 * @param key
 * @param params
 * @param queryFlag
 */
export const useInfinityQueryByRequest = <T = any, k = TQueryParamsGetData>(
  request: (params: k) => Promise<any>,
  key: string | string[],
  params: k,
  queryFlag = false,
) => {
  const token = checkAuth()
  return useInfiniteQuery<TResDataListApi<T[]>, TResApiErr>({
    queryKey: [key, JSON.stringify(params)].flat(),
    queryFn: ({ pageParam = 1 }) => request({ ...params, page: pageParam }),
    refetchOnWindowFocus: true,
    retry: 2,
    enabled: !!token && !!params && queryFlag,
    getNextPageParam: (lastPage) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      lastPage?.page < Math.ceil(lastPage?.total / lastPage?.limit) ? lastPage?.page + 1 : undefined,
  })
}
