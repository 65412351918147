import { request } from '@queries/apis/config'
import { TPathAdv, TPostAdv, TQueryAdv } from '@modules/advertisements'

export const postAdv = (data: TPostAdv) => request({ url: 'advertisement', method: 'POST', data })
export const getListAdv = (params: TQueryAdv, token?: string) =>
  request({ url: 'advertisement', method: 'GET', params }, { token })
export const getAdvById = (id: string, token?: string) =>
  request({ url: `advertisement/${id}`, method: 'GET' }, { token })
export const patchAdvById = (id: string, data: TPathAdv) =>
  request({ url: `advertisement/${id}`, method: 'PATCH', data })
export const removeAdvById = (id: string) => request({ url: `advertisement/${id}`, method: 'DELETE' })
export const removeManyAdvByIds = (ids: string[]) =>
  request({ url: 'advertisement/delete-many', method: 'POST', data: { ids } })
export const getTotalAdv = (token?: string) => request({ url: 'advertisement/total', method: 'GET' }, { token })
