import { useMutation, useQuery } from 'react-query'
import { getListOption, getOptionsByKeys, postPatchOption, removeOptionsByKeys } from '@queries/apis'
import { TOption, TQueryOption, TQueryOptionByKeys, TRemoveOptionByKeys } from '@modules/option'
import { LIST_OPTION, LIST_OPTION_BY_KEYS } from '@queries/keys'
import { checkAuth } from '@libs/localStorage'
import { notification } from 'antd'
import { NSuccess } from '@configs/const.config'
import { TResApi, TResApiErr } from '@configs/interface.config'
import { queryClient } from '@src/queries'

/**
 *
 * @method useMutationPostPatchOption
 */
export const useMutationPostPatchOption = () => useMutation(postPatchOption)

/**
 *
 * @method useQueryListOption
 * @param {TQueryOption}params
 */
export const useQueryListOption = (params: TQueryOption) => {
  const accessToken = checkAuth()
  return useQuery({
    queryKey: [LIST_OPTION, JSON.stringify(params)],
    queryFn: () => getListOption(params),
    enabled: !!accessToken,
  })
}

/**
 *
 * @method useQueryOptionsByKeys
 * @param {TQueryOptionByKeys}params
 */
export const useQueryOptionsByKeys = (params: TQueryOptionByKeys) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TOption[]>, TResApiErr, TOption[]>({
    queryKey: [LIST_OPTION_BY_KEYS, JSON.stringify(params)],
    queryFn: () => getOptionsByKeys(params),
    enabled: !!accessToken,
    select: (data) => data.data,
  })
}

/**
 *
 * @method useMutationRemoveOptionsByKeys
 */
export const useMutationRemoveOptionsByKeys = () =>
  useMutation({
    mutationFn: removeOptionsByKeys,
    onSuccess: async (res: TResApi, variables: TRemoveOptionByKeys) => {
      await queryClient.invalidateQueries([LIST_OPTION])
      queryClient.removeQueries([LIST_OPTION_BY_KEYS, variables['key[]'].flat()])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })
