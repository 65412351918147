import { request } from '@queries/apis/config'
import { TPatchCompany, TPostCompany, TQueryCompany } from '@modules/company'

export const postCompany = (data: TPostCompany) => request({ url: 'company', method: 'POST', data })
export const getListCompany = (params: TQueryCompany) => request({ url: 'company', method: 'GET', params })
export const getTotalCompany = () => request({ url: 'company/total', method: 'GET' })
export const getCompanyById = (id: string) => request({ url: `company/${id}`, method: 'GET' })
export const patchCompanyById = ({ id, data }: { id: string; data: TPatchCompany }) =>
  request({ url: `company/${id}`, method: 'PATCH', data })
export const removeCompanyById = (id: string) => request({ url: `company/${id}`, method: 'DELETE' })
export const putSlugCompanyById = ({ id, slug }: { id: string; slug: string }) =>
  request({ url: `company/${id}/slug`, method: 'PUT', data: { slug } })
export const removeManyCompanyByIds = (ids: string[]) =>
  request({ url: 'company/delete-many', method: 'POST', data: { ids } })
export const reIndexCompaniesEs = () => request({ url: 'company/re-index-elasticsearch', method: 'POST' })
export const reIndexCompanyEsById = (id: string) =>
  request({ url: `company/re-index-elasticsearch/${id}`, method: 'PATCH' })
