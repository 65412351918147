import { queryClient } from '@queries/index'
import { TResApi } from '@configs/interface.config'

export const cachingUpdateDetail = <T>(queryKey: string | string[], id: string, body: { [key: string]: any }) => {
  queryClient.setQueryData(queryKey, () => {
    const old = queryClient.getQueryData<TResApi<T>>(queryKey)
    if (old && old?.data)
      return {
        ...old,
        data: { ...old.data, ...body },
      }
    return old
  })
}
