/* eslint-disable no-unused-vars */
export enum ACCESS {
  GENERAL = 'GENERAL',
  // User Module
  CREATE_USER = 'CREATE_USER',
  LIST_USERS = 'LIST_USERS',
  VIEW_USER = 'VIEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',

  // Deleted User
  LIST_DELETED_USER = 'LIST_DELETED_USER',
  VIEW_DELETED_USER = 'VIEW_DELETED_USER',
  RESTORE_USER = 'RESTORE_USER',

  // Role Module
  LIST_ROLES = 'LIST_ROLES',
  LIST_PERMISSION = 'LIST_PERMISSION',
  CREATE_ROLE = 'CREATE_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',

  // Media Module
  CREATE_MEDIA = 'CREATE_MEDIA',
  LIST_MEDIAS = 'LIST_MEDIAS',
  VIEW_MEDIA = 'VIEW_MEDIA',
  UPDATE_MEDIA = 'UPDATE_MEDIA',
  DELETE_MEDIA = 'DELETE_MEDIA',

  // Taxonomy module
  CREATE_TAXONOMY = 'CREATE_TAXONOMY',
  LIST_TAXONOMY = 'LIST_TAXONOMY',
  VIEW_TAXONOMY = 'VIEW_TAXONOMY',
  UPDATE_TAXONOMY = 'UPDATE_TAXONOMY',
  DELETE_TAXONOMY = 'DELETE_TAXONOMY',
  RE_INDEX_RELATIONSHIP_TAXONOMY = 'RE_INDEX_RELATIONSHIP_TAXONOMY',

  // Post module
  CREATE_POST = 'CREATE_POST',
  LIST_POST = 'LIST_POST',
  VIEW_POST = 'VIEW_POST',
  UPDATE_POST = 'UPDATE_POST',
  DELETE_POST = 'DELETE_POST',
  RE_INDEX_POST_ES = 'RE_INDEX_POST_ES',

  // Notification Template, Notification module
  SEND_NOTIFICATION_TEMPLATE = 'SEND_NOTIFICATION_TEMPLATE',
  CREATE_NOTIFICATION_TEMPLATE = 'CREATE_NOTIFICATION_TEMPLATE',
  LIST_NOTIFICATION_TEMPLATE = 'LIST_NOTIFICATION_TEMPLATE',
  VIEW_NOTIFICATION_TEMPLATE = 'VIEW_NOTIFICATION_TEMPLATE',
  UPDATE_NOTIFICATION_TEMPLATE = 'UPDATE_NOTIFICATION_TEMPLATE',
  DELETE_NOTIFICATION_TEMPLATE = 'DELETE_NOTIFICATION_TEMPLATE',
  CREATE_NOTIFICATION = 'CREATE_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',

  // Option module
  CREATE_UPDATE_OPTION = 'CREATE_UPDATE_OPTION',
  LIST_OPTION = 'LIST_OPTION',
  DELETE_OPTION = 'DELETE_OPTION',

  // Advertisement Module
  CREATE_ADV = 'CREATE_ADV',
  LIST_ADV = 'LIST_ADV',
  VIEW_ADV = 'VIEW_ADV',
  UPDATE_ADV = 'UPDATE_ADV',
  DELETE_ADV = 'DELETE_ADV',

  // Company Module
  CREATE_COMPANY = 'CREATE_COMPANY',
  LIST_COMPANY = 'LIST_COMPANY',
  VIEW_COMPANY = 'VIEW_COMPANY',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
  DELETE_COMPANY = 'DELETE_COMPANY',
  RE_INDEX_COMPANY_ES = 'RE_INDEX_COMPANY_ES',
}
