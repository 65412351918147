import { Breadcrumb as BreadcrumbAntd } from 'antd'
import { Link, useLocation, matchRoutes } from 'react-router-dom'
import routeConfig from '@src/route/routeConfig'

function Breadcrumb() {
  const location = useLocation()
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const breadcrumbItem = pathSnippets.map((path, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    const routes = routeConfig.map((r) => ({ path: r.path, element: r.Element }))
    const checkRoute = matchRoutes(routes, url)
    return (
      <BreadcrumbAntd.Item key={url}>
        {checkRoute && index < pathSnippets.length - 1 ? <Link to={url}>{path}</Link> : path}
      </BreadcrumbAntd.Item>
    )
  })

  const breadcrumbItems = [
    <BreadcrumbAntd.Item key="home">
      <Link to="/">Dashboard</Link>
    </BreadcrumbAntd.Item>,
  ].concat(breadcrumbItem)

  return <BreadcrumbAntd>{breadcrumbItems}</BreadcrumbAntd>
}

export default Breadcrumb
