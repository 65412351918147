import lazyLoading from '@src/components/widgets/LazyLoading'
import { ACCESS } from '@src/configs/permission'
import React from 'react'
import { PathRouteProps } from 'react-router-dom'

export interface TRouteConfig extends PathRouteProps {
  Element: React.FC
  key: ACCESS
}

const Dashboard = lazyLoading(() => import('@pages/dashboard'))

const ProfilePage = lazyLoading(() => import('@pages/user/profile'))

const Role = lazyLoading(() => import('@pages/role'))

const User = lazyLoading(() => import('@pages/user'))
const CreateUser = lazyLoading(() => import('@pages/user/create'))
const DetailUser = lazyLoading(() => import('@pages/user/detail'))

const Media = lazyLoading(() => import('@pages/media'))

const Post = lazyLoading(() => import('@pages/post'))
const CreatePost = lazyLoading(() => import('@pages/post/create'))
const DetailPost = lazyLoading(() => import('@pages/post/detail'))
const Category = lazyLoading(() => import('@pages/post/category'))
const CreateCategory = lazyLoading(() => import('@pages/post/category/create'))
const DetailCategory = lazyLoading(() => import('@pages/post/category/detail'))

const Taxonomy = lazyLoading(() => import('@pages/taxonomy'))
const TaxonomyDetail = lazyLoading(() => import('@pages/taxonomy/detail'))

const Advertisement = lazyLoading(() => import('@pages/advertisement'))
const DetailAdvertisement = lazyLoading(() => import('@pages/advertisement/detail'))
const CreateAdvertisement = lazyLoading(() => import('@pages/advertisement/create'))

const Company = lazyLoading(() => import('@pages/company'))
const DetailCompany = lazyLoading(() => import('@pages/company/detail'))

const CustomFieldsGeneral = lazyLoading(() => import('@pages/custom-fields/general'))
const CustomFieldsCompanyPage = lazyLoading(() => import('@pages/custom-fields/pages/company'))
const CustomFieldsNewsPage = lazyLoading(() => import('@pages/custom-fields/pages/news'))
const CustomFieldsContactPage = lazyLoading(() => import('@pages/custom-fields/pages/contact'))
const CustomFieldsSearchByTaxCodePage = lazyLoading(() => import('@pages/custom-fields/pages/search-by-tax-code'))

const routeConfig: TRouteConfig[] = [
  {
    path: '/',
    Element: Dashboard,
    key: ACCESS.GENERAL,
  },

  {
    path: '/profile',
    Element: ProfilePage,
    key: ACCESS.GENERAL,
  },

  // Role
  {
    path: '/role',
    Element: Role,
    key: ACCESS.LIST_ROLES,
  },
  {
    path: '/create-role',
    Element: Role,
    key: ACCESS.CREATE_ROLE,
  },
  {
    path: '/role/:id',
    Element: Role,
    key: ACCESS.VIEW_ROLE,
  },

  // User
  {
    path: '/user',
    Element: User,
    key: ACCESS.LIST_USERS,
  },
  {
    path: '/create-user',
    Element: CreateUser,
    key: ACCESS.CREATE_USER,
  },
  {
    path: '/user/:id',
    Element: DetailUser,
    key: ACCESS.VIEW_USER,
  },

  // Media
  {
    path: '/media',
    Element: Media,
    key: ACCESS.LIST_MEDIAS,
  },

  // Post
  {
    path: '/post',
    Element: Post,
    key: ACCESS.LIST_POST,
  },
  {
    path: '/create-post',
    Element: CreatePost,
    key: ACCESS.CREATE_POST,
  },
  {
    path: '/post/:id',
    Element: DetailPost,
    key: ACCESS.VIEW_POST,
  },
  {
    path: '/category',
    Element: Category,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: '/create-category',
    Element: CreateCategory,
    key: ACCESS.CREATE_TAXONOMY,
  },
  {
    path: '/category/:id',
    Element: DetailCategory,
    key: ACCESS.VIEW_TAXONOMY,
  },

  // Taxonomy
  {
    path: 'taxonomy/:postType',
    Element: Taxonomy,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: 'taxonomy/:postType/:id',
    Element: TaxonomyDetail,
    key: ACCESS.VIEW_TAXONOMY,
  },

  // Advertisement
  {
    path: '/advertisement',
    Element: Advertisement,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: '/create-advertisement',
    Element: CreateAdvertisement,
    key: ACCESS.CREATE_TAXONOMY,
  },
  {
    path: '/advertisement/:id',
    Element: DetailAdvertisement,
    key: ACCESS.VIEW_TAXONOMY,
  },

  // Company
  {
    path: '/company',
    Element: Company,
    key: ACCESS.LIST_COMPANY,
  },
  {
    path: '/company/:id',
    Element: DetailCompany,
    key: ACCESS.VIEW_COMPANY,
  },

  // Custom Fields
  {
    path: 'custom-fields/general',
    Element: CustomFieldsGeneral,
    key: ACCESS.LIST_OPTION,
  },
  {
    path: 'custom-fields/pages/company',
    Element: CustomFieldsCompanyPage,
    key: ACCESS.LIST_OPTION,
  },
  {
    path: 'custom-fields/pages/search-by-tax-code',
    Element: CustomFieldsSearchByTaxCodePage,
    key: ACCESS.LIST_OPTION,
  },
  {
    path: 'custom-fields/pages/news',
    Element: CustomFieldsNewsPage,
    key: ACCESS.LIST_OPTION,
  },
  {
    path: 'custom-fields/pages/contact',
    Element: CustomFieldsContactPage,
    key: ACCESS.LIST_OPTION,
  },
]

export default routeConfig
