import {
  EOrder,
  EOrderBy,
  TDocWithAuthor,
  TDocWithTimestamps,
  TDocWithVersion,
  TQueryParamsGetData,
} from '@src/configs/interface.config'

export type TTaxonomyPopulate = {
  _id: string
  name: string
  nameSort: string
  slug: string
  description: string
  parent: string
  postType: string
  index: number
}

export type TTaxonomy = {
  _id: string
  name: string
  nameSort: string
  slug: string
  description: string
  parent: TTaxonomy
  postType: string
  left: number
  right: number
  index: number
} & TDocWithTimestamps &
  TDocWithAuthor &
  TDocWithVersion

export type TQueryTaxonomy = TQueryParamsGetData<{
  parentId?: string
  isGenealogy?: 0 | 1
  postType?: string
}>

export type TQueryTaxonomyMakeTree = { order?: EOrder; orderBy?: EOrderBy; taxonomyId?: string }

export type TTaxonomyMakeTree = {
  _id: string
  name: string
  nameSort: string
  slug: string
  parent: string | null
  children: TTaxonomyMakeTree[]
  text: string
  value: string
  index: number
}

export type TPostTaxonomy = {
  name: string
  description?: string
  parentId?: string
  postType?: string
  index?: number
}

export type TPatchTaxonomy = Partial<Omit<TPostTaxonomy, 'postType' | 'parentId'>>

export type TMoveTaxonomy = {
  newParentId?: string
}

export enum EPostType {
  POST_TYPE_POST = 'post',
  POST_TYPE_INDUSTRY = 'industry',
  POST_TYPE_INCORPORATION_TYPE = 'incorporation-type',
  POST_TYPE_COUNTRY = 'country',
}
