import { TAdv, TPathAdv, TQueryAdv } from '@modules/advertisements'
import { checkAuth } from '@libs/localStorage'
import { useMutation, useQuery } from 'react-query'
import {
  getAdvById,
  getListAdv,
  getTotalAdv,
  patchAdvById,
  postAdv,
  removeAdvById,
  removeManyAdvByIds,
} from '@queries/apis'
import { DETAIL_ADV, LIST_ADV, TOTAL_ADV } from '@queries/keys'
import { TResApi, TResApiErr, TResDataListApi } from '@configs/interface.config'
import { TPost } from '@src/modules'
import { queryClient } from '@src/queries'
import { notification } from 'antd'
import { NSuccess } from '@configs/const.config'

/**
 *
 * @method useMutationPostAdv
 */
export const useMutationPostAdv = () =>
  useMutation({
    mutationFn: postAdv,
    onSuccess: async (res: TResApi<TPost>) => {
      await queryClient.invalidateQueries([LIST_ADV])
      await queryClient.invalidateQueries([TOTAL_ADV])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 *
 * @method useQueryListAdv
 * @param {TQueryAdv}params
 */
export const useQueryListAdv = (params: TQueryAdv) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TAdv[]>, TResApiErr>({
    queryKey: [LIST_ADV, JSON.stringify(params)],
    queryFn: () => getListAdv(params),
    enabled: !!accessToken,
  })
}

/**
 *
 * @method useQueryAdvTotal
 */
export const useQueryAdvTotal = () => {
  const accessToken = checkAuth()
  return useQuery<TResApi<number>>({
    queryKey: [TOTAL_ADV],
    queryFn: () => getTotalAdv(accessToken),
    enabled: !!accessToken,
  })
}

/**
 *
 * @method useQueryAdvById
 * @param {string}id
 */
export const useQueryAdvById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TAdv>>({
    queryKey: [DETAIL_ADV, id],
    queryFn: () => getAdvById(id),
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPatchAdvById
 */
export const useMutationPatchAdvById = () =>
  useMutation({
    mutationFn: ({ id, data }: { id: string; data: TPathAdv }) => patchAdvById(id, data),
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.invalidateQueries([LIST_ADV])
      await queryClient.invalidateQueries([DETAIL_ADV, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 *
 * @method useMutationRemoveAdvById
 */
export const useMutationRemoveAdvById = () =>
  useMutation({
    mutationFn: removeAdvById,
    onSuccess: async (res: TResApi, id) => {
      await queryClient.invalidateQueries([LIST_ADV])
      queryClient.removeQueries([DETAIL_ADV, id])
      await queryClient.invalidateQueries([TOTAL_ADV])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 *
 * @method useMutationRemoveManyAdvByIds
 */
export const useMutationRemoveManyAdvByIds = () => useMutation({ mutationFn: removeManyAdvByIds })
