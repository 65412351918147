import { NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchTaxonomy, TQueryTaxonomy, TQueryTaxonomyMakeTree, TTaxonomy, TTaxonomyMakeTree } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  getListTaxonomy,
  getTaxonomyById,
  getTaxonomyMakeTree,
  moveTaxonomyById,
  patchTaxonomyById,
  postTaxonomy,
  putSlugTaxonomyById,
  reIndexRelationshipTaxonomyById,
  removeTaxonomyById,
} from '../apis'
import { DETAIL_TAXONOMY, LIST_TAXONOMY, MAKE_TREE_TAXONOMY, TAXONOMY_BY_FILTER } from '../keys'

/**
 * @method useQueryListTaxonomy
 * @param {TQueryTaxonomy}params
 * @param {string}token
 * @returns
 */
export const useQueryListTaxonomy = (params: TQueryTaxonomy, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TTaxonomy[]>, TResApiErr>(
    [LIST_TAXONOMY, JSON.stringify(params)],
    () => getListTaxonomy(params, accessToken),
    {
      enabled: (!!accessToken && !!params?.postType) || (!!accessToken && !!params?.parentId),
    },
  )
}

/**
 * @method useQueryTaxonomyMakeTree
 * @param {string}postType
 * @param {string}token
 * @param {TQueryTaxonomyMakeTree}params
 * @returns
 */
export const useQueryTaxonomyMakeTree = (postType: string, token?: string, params?: TQueryTaxonomyMakeTree) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TTaxonomyMakeTree>, TResApiErr>(
    [MAKE_TREE_TAXONOMY, postType, params],
    () => getTaxonomyMakeTree(postType, token, params),
    {
      enabled: !!accessToken && !!postType,
    },
  )
}

/**
 * @method useQueryTaxonomyById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryTaxonomyById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TTaxonomy>, TResApiErr>([DETAIL_TAXONOMY, id], () => getTaxonomyById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

/**
 * @method useMutationPostTaxonomy
 */
export const useMutationPostTaxonomy = () =>
  useMutation({
    mutationFn: postTaxonomy,
    onSuccess: async (res: TResApi<TTaxonomy>) => {
      await queryClient.invalidateQueries([TAXONOMY_BY_FILTER])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 * @method useMutationPatchTaxonomyById
 */
export const useMutationPatchTaxonomyById = () =>
  useMutation(({ id, data }: { id: string; data: TPatchTaxonomy }) => patchTaxonomyById(id, data), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([TAXONOMY_BY_FILTER])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 * @method useMutationMoveTaxonomyById
 */
export const useMutationMoveTaxonomyById = () =>
  useMutation(({ id, newParentId }: { id: string; newParentId?: string }) => moveTaxonomyById(id, newParentId), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.invalidateQueries([DETAIL_TAXONOMY, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 * @method useMutationPutSlugTaxonomyById
 */
export const useMutationPutSlugTaxonomyById = () =>
  useMutation(({ id, slug }: { id: string; slug: string }) => putSlugTaxonomyById(id, slug), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([TAXONOMY_BY_FILTER])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 * @method useMutationRemoveTaxonomyById
 */
export const useMutationRemoveTaxonomyById = () => useMutation(removeTaxonomyById)

/**
 * @method useMutationReIndexRelationShipTaxonomyById
 */
export const useMutationReIndexRelationShipTaxonomyById = () =>
  useMutation((id: string) => reIndexRelationshipTaxonomyById(id))
