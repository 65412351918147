import { useMutation, useQuery } from 'react-query'
import {
  getCompanyById,
  getListCompany,
  getTotalCompany,
  patchCompanyById,
  postCompany,
  putSlugCompanyById,
  reIndexCompaniesEs,
  reIndexCompanyEsById,
  removeCompanyById,
  removeManyCompanyByIds,
} from '@queries/apis'
import { notification } from 'antd'
import { NSuccess } from '@configs/const.config'
import { queryClient } from '@src/queries'
import { DETAIL_COMPANY, LIST_COMPANY, TOTAL_COMPANY } from '@queries/keys'
import { TResApi, TResApiErr, TResDataListApi } from '@configs/interface.config'
import { TCompany, TCompanyItem, TQueryCompany } from '@modules/company'
import { checkAuth } from '@libs/localStorage'
import { cachingUpdateDetail } from '@queries/caching'

/**
 *
 * @method useMutationPostCompany
 */
export const useMutationPostCompany = () =>
  useMutation({
    mutationFn: postCompany,
    onSuccess: async (res: TResApi<TCompany>) => {
      await queryClient.invalidateQueries([LIST_COMPANY])
      await queryClient.invalidateQueries([TOTAL_COMPANY])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 *
 * @method useQueryListCompany
 */
export const useQueryListCompany = (params: TQueryCompany) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TCompanyItem[]>, TResApiErr>({
    queryKey: [LIST_COMPANY, JSON.stringify(params)],
    queryFn: () => getListCompany(params),
    enabled: !!accessToken,
  })
}

/**
 *
 * @method useQueryCompanyTotal
 * @returns
 */
export const useQueryCompanyTotal = () => {
  const accessToken = checkAuth()
  return useQuery<TResApi<number>>({ enabled: !!accessToken, queryFn: getTotalCompany, queryKey: [TOTAL_COMPANY] })
}

/**
 *
 * @method useQueryCompanyById
 * @param {string}id
 * @returns
 */
export const useQueryCompanyById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TCompany>, TResApiErr>({
    queryFn: () => getCompanyById(id),
    queryKey: [DETAIL_COMPANY, id],
    enabled: !!accessToken && !!id,
  })
}

// export const use

/**
 *
 * @method useMutationPatchCompanyById
 */
export const useMutationPatchCompanyById = () =>
  useMutation({
    mutationFn: patchCompanyById,
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.invalidateQueries([LIST_COMPANY])
      await queryClient.invalidateQueries([DETAIL_COMPANY, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 *
 * @method useMutationRemoveCompanyById
 */
export const useMutationRemoveCompanyById = () =>
  useMutation({
    mutationFn: removeCompanyById,
    onSuccess: async (res: TResApi, id) => {
      await queryClient.invalidateQueries([LIST_COMPANY])
      queryClient.removeQueries([DETAIL_COMPANY, id])
      await queryClient.invalidateQueries([TOTAL_COMPANY])
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 *
 * @method useMutationRemoveManyCompanyByIds
 */
export const useMutationRemoveManyCompanyByIds = () => useMutation({ mutationFn: removeManyCompanyByIds })

/**
 *
 * @method useMutationPutSlugCompanyById
 * @returns
 */
export const useMutationPutSlugCompanyById = () =>
  useMutation({
    mutationFn: putSlugCompanyById,
    onSuccess: (res: TResApi, { id, slug }) => {
      cachingUpdateDetail([DETAIL_COMPANY, id], id, { slug })
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 *
 * @method useMutationReIndexEsCompanies
 */
export const useMutationReIndexEsCompanies = () => useMutation(reIndexCompaniesEs)

/**
 *
 * @method useMutationReIndexEsCompanyById
 */
export const useMutationReIndexEsCompanyById = () => useMutation((id: string) => reIndexCompanyEsById(id))
